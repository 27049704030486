/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import Phone from "../../statics/phone.png";
import Clock from "../../statics/clock.png";
import Live from "../../statics/live.png";
import Different from "../../statics/different.png";
import Workout from "../../statics/workout.png";
import Pain from "../../statics/pain.png";
import theme from "../../theme";

const styles = {
  cntnr: {
    mt: { xs: "3.5rem", md: "5rem" },
    maxWidth: "75rem",
    px: "2rem",
  },
  title: {
    color: "primary.main",
    fontSize: { xs: "1.875rem", sd: "2.5rem" },
    fontWeight: 750,
  },
  cardCntnr: {
    mt: "1.5rem",
  },
  textBox: {
    p: "0.85rem",
    backgroundColor: "#dedede",
    borderRadius: "0.85rem",
    mb: { xs: "1.5rem", md: 0 },
    // color: "secondary.dark",
  },
  mark: css`
    color: ${theme.palette.secondary.dark};
    background: linear-gradient(180deg, transparent 40%, #7cf2f7 0);
    margin: 0 0.2rem;
  `,
  photo: css`
    max-height: 250px;
    max-width: 350px;
  `,
  suggestionTxt: {
    mt: { xs: "2rem", md: "4rem" },
    p: "0.85rem",
    fontWeight: 500,
    backgroundColor: "#73208a",
    color: "#fff",
    borderRadius: "0.85rem",
  },
};

const parts = [
  {
    text: [
      <span>
        <mark css={styles.mark}>
          <strong>فقط با یه گوشی!</strong>
        </mark>
        اینجا ثبت نام رو انجام میدی، بعد وارد
        <mark css={styles.mark}>
          <strong>اینستاگرام‌</strong>
        </mark>
        باشگاه و یا حساب کاربری خودت در
        <mark css={styles.mark}>
          <strong>همین سایت</strong>
        </mark>
        (بدون نیاز به فیلتر شکن) میشی، و به صورت لایو با من و هزاران نفر دیگه
        تمرین می‌کنی.
      </span>,
      <span>
        تمرینات
        <mark css={styles.mark}>
          <strong>12 جلسه در ماه‌</strong>
        </mark>
        ،یعنی
        <mark css={styles.mark}>
          <strong>3 جلسه در هفته</strong>
        </mark>
        ‌برگزار میشه.
      </span>,
    ],
    photo: Phone,
  },
  {
    text: [
      <span>
        {/* سعی من بر اینه که لایوها رو
        <mark css={styles.mark}>
          <strong>‌ساعتی از روز</strong>
        </mark>
        ‌برگزار کنم که شما امکان شرکت در لایو رو داشته باشید. */}
        جلسات فول‌بادی
        <mark css={styles.mark}>
          <strong>‌ساعت 18 تا 19</strong>
        </mark>
        ، جلسات هوازی
        <mark css={styles.mark}>
          <strong>‌ساعت 19 تا 19:40</strong>
        </mark>{" "}
        و جلسات تمرینات اصلاحی هم{" "}
        <mark css={styles.mark}>
          <strong>ساعت 20 تا 20:40</strong>
        </mark>{" "}
        روزهای{" "}
        <mark css={styles.mark}>
          <strong>زوج</strong>
        </mark>{" "}
        برگزار میشه.
      </span>,
    ],
    photo: Clock,
  },
  {
    text: [
      <span>
        <mark css={styles.mark}>
          <strong>حالا اگه به لایو نرسیدیم چی؟</strong>
        </mark>
      </span>,
      <span>
        لایوها به مدت‌
        <mark css={styles.mark}>
          <strong>‌یک ماه در تلگرام ذخیره میشه‌</strong>
        </mark>
        ، و حتی بعد از اتمام لایو، شما می‌تونید با ویدیوی ذخیره شده،
        <mark css={styles.mark}>
          <strong>‌با همون کیفیت‌</strong>
        </mark>
        با من تمرین کنید.
      </span>,
    ],
    photo: Live,
  },
  {
    text: [
      <span>
        <mark css={styles.mark}>
          <strong>تمرینات‌</strong>
        </mark>
        و همچنین
        <mark css={styles.mark}>
          <strong>‌رژیم‌های غذایی رایگان‌</strong>
        </mark>
        ‌به صورتی طراحی شده، که
        <mark css={styles.mark}>
          <strong>‌با هر تیپ بدنی‌</strong>
        </mark>
        ‌و
        <mark css={styles.mark}>
          <strong>‌هر هدف ورزشی‌</strong>
        </mark>
        ‌که شما دارید مطابقت داشته باشه.
      </span>,
    ],
    photo: Different,
  },
  {
    text: [
      <span>
        مهم‌ترین هدفی که این دوره‌ها دارن، اینه که شما بتونید هر جایی که هستید،
        به بهترین شکل ممکن، و
        <mark css={styles.mark}>
          <strong>بدون نیاز به باشگاه‌</strong>
        </mark>
        ‌تمرین کنید، و
        <mark css={styles.mark}>
          <strong>همیشه خوش استایل باشید</strong>
        </mark>
        .
      </span>,
    ],
    photo: Workout,
  },
  {
    text: [
      <span>
        برای عزیزانی که‌
        <mark css={styles.mark}>
          <strong>‌مشکل مفاصل یا نقص خاصی ( مثل کمر درد و زانو درد )</strong>
        </mark>
        دارن، همزمان‌
        <mark css={styles.mark}>
          <strong>‌حرکات اصلاحی جایگزین‌</strong>
        </mark>
        ‌ارائه داده میشه تا این عزیزان هم بتونن‌
        <mark css={styles.mark}>
          <strong>‌بدون آسیب‌</strong>
        </mark>
        ‌همگام با بقیه تمرین کنن.
      </span>,
    ],
    photo: Pain,
  },
];

const suggestionTxt = [
  "پیشنهاد ویژه:",
  "اگر میخوای سریع تر نتیجه بگیری و سرعت فرم دهی عضلات و چربی سوزی رو چندین برابر کنی، در کنار دوره فول بادی، تمرینات هوازی رو هم انجام بده.",
  "تمرینات هوازی همون روز بلافاصله بعد از تمرینات فول بادی شروع میشه.",
];
export default function Highlights() {
  return (
    <Grid2 container xs={12} justifyContent="center">
      <Grid2 container xs={12} sx={styles.cntnr}>
        <Grid2 container xs={12} sx={styles.title}>
          حالا چجوری؟
        </Grid2>
        {parts.map((item, index) => {
          return (
            <Grid2
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={styles.cardCntnr}
            >
              {index % 2 === 1 && (
                <Grid2
                  container
                  xs={12}
                  md={5}
                  justifyContent="center"
                  alignItems="center"
                  sx={[
                    styles.photoCtnr,
                    { display: { xs: "none", md: "flex" } },
                  ]}
                >
                  <img src={item.photo} alt="phone" css={styles.photo} />
                </Grid2>
              )}
              <Grid2
                container
                xs={12}
                md={7}
                // justifyContent="center"
                alignItems="center"
                sx={styles.textBox}
              >
                {item.text.map((t) => {
                  return (
                    <>
                      {t}
                      {item.text.length > 1 && (
                        <Grid2 xs={12} sx={{ mt: "0.5rem" }} />
                      )}
                    </>
                  );
                })}
              </Grid2>
              {/* {index % 2 === 0 && ( */}
              <Grid2
                container
                xs={12}
                md={5}
                justifyContent="center"
                alignItems="center"
                sx={[
                  styles.photoCtnr,
                  {
                    display: {
                      xs: "flex",
                      md: index % 2 === 0 ? "flex" : "none",
                    },
                  },
                ]}
              >
                <img src={item.photo} alt="phone" css={styles.photo} />
              </Grid2>
              {/* )} */}
            </Grid2>
          );
        })}

        <Grid2
          container
          xs={12}
          // justifyContent="center"
          alignItems="center"
          sx={styles.suggestionTxt}
        >
          {suggestionTxt.map((t, index) => {
            return (
              <Grid2
                xs={12}
                sx={[
                  index !== suggestionTxt.length - 1 && { mb: "0.5rem" },
                  index === 0 && { fontWeight: 800, fontSize: "1.25rem" },
                ]}
              >
                {t}
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
